import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, LanguageContext } from "ToolboxUtils/web/context/context";
import { IconTextBubbleWrapper } from './icon-text-bubble.styles';
import Bubble from "ToolboxComponents/commons/icons/bubble/bubble";
import Text from 'ToolboxComponents/commons/texts/text/text';

const texts = {
  fr : {
    call: `page.concierge.sidebarPhone`,
    sms: `page.concierge.sidebarText`,
    chat: `page.concierge.sidebarChat`,
    messenger: `page.concierge.sidebarMessenger`,
    mail: `page.concierge.sidebarEmail`,
    place: `page.concierge.sidebarAddress`,
  },
  en: {
    call: `page.concierge.sidebarPhone`,
    sms: `page.concierge.sidebarText`,
    chat: `page.concierge.sidebarChat`,
    messenger: `page.concierge.sidebarMessenger`,
    mail: `page.concierge.sidebarEmail`,
    place: `page.concierge.sidebarAddress`,
  }
};

const IconTextBubble = props => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();

  let link, target;
  if (props.aTag) {
    if (props.type === 'call') {
      link = `tel:${props.text}`;
    } else if (props.type === 'sms') {
      link = `sms:${props.text}`;
    } else if (props.type === 'mail') {
      link = `mailto:${props.text}`;
    } else {
      link = props.link;
      target = '_blank';
    }
  }
  return (
    <IconTextBubbleWrapper theme={theme} {...props}>
      {props.aTag ?
        <a target={target} href={link} className='wrapper'>
          <Bubble bubble={props.bubble} />
          <Text
              className="description"
              path={`${texts[language][props.type]}`}
              data={{ phone: `${props.text}`, address: `${props.text}`}}
          />
        </a>
      : <div className='wrapper'>
          <Bubble bubble={props.bubble} />
          <Text
              className="description"
              path={`${texts[language][props.type]}`}
              data={{ phone: `${props.text}`, address: `${props.text}`}}
          />
        </div>
      }
    </IconTextBubbleWrapper>
  );
}

IconTextBubble.propTypes = {
  text: PropTypes.string,
  bubble: PropTypes.object.isRequired,
  aTag: PropTypes.bool.isRequired,
  stick: PropTypes.bool.isRequired,
  type: PropTypes.string,
}

export default IconTextBubble;
