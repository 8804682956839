import React, { useState, useEffect, useContext } from 'react';
import { GeolocationContext, LanguageContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import { Helmet } from "react-helmet";
import ConciergeView from 'ToolboxComponents/webapp/pages/concierge-view/concierge-view';
import Loader from 'ToolboxComponents/commons/loader/loader';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';

import { PAGE_LOAD_WAITING } from 'Params/globals';

const Concierge = props => {
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [geolocation] = useContext(GeolocationContext).usePath();

  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!concierge.isComplete) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  const sendLogs = error => {
    apiChuchoteurs.post(`/logs`, {
      body: {error: {...error, type: 'front'}}
    })
  };

  let concierge = useApiChuchoteurs.get(`/concierges`, {
    params: {filter: 'closest', latitude: geolocation.coordinates.latitude, longitude: geolocation.coordinates.longitude, language, isInZone: whiteLabel.isInZone, whiteLabelId: whiteLabel.id},
    depends: geolocation && whiteLabel,
    onComplete: results => {
      if (results.isHttpError && results.error) {
        sendLogs(results.error);
      }
    }
  });
  useIsContentLoaded(concierge.isComplete);

  return (
    <>
      {concierge.isComplete
        ? <ConciergeView concierge={concierge.data[0]} />
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
    </>
  );
}

export default Concierge;
