import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, WebappContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import IconTextBubble from 'ToolboxComponents/commons/texts/icon-text-bubble/icon-text-bubble';
import formatAddress from 'ToolboxUtils/web/formats/address';
import { ContactWrapper } from './contact.styles';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import Text from 'ToolboxComponents/commons/texts/text/text';

const Contact = props => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const { phone, sms, chat, messenger, mail, name, address1, address2, postalCode, city, displayedSchedule } = props.informations;
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [marginBottom, setMarginBottom] = useState(0);
  useEffect(() => {
    setTimeout(function(){
      const mobileNav = document.getElementById('mobile-nav');
      if (mobileNav) {
        setMarginBottom(`${mobileNav.offsetHeight}px`);
      }
    }, 100);
  }, []);

  const address = formatAddress({data: props.informations, options: {firstBreak: '<br />', secondBreak: ','}});
  return (
    <ContactWrapper id='contact' className='contact' theme={theme} marginBottom={marginBottom}>
      {isMobile &&
        <h2>
            <Text path={`page.concierge.expertsTitle`} />
        </h2>
      }
      {displayedSchedule && displayedSchedule !== "" && <p className='subtitle'>{displayedSchedule}</p>}
      {phone !== undefined && phone !== null && phone !== "" &&
        <IconTextBubble
          text={phone}
          bubble={{
            text: whiteLabel.textualContent.page.concierge.sidebarPhoneIcon,
            icon: {class: 'icon adn adn-phone'},
          }}
          type='call'
          aTag={true}
          onClick={() => {analytics.sendPhoneContactEvent(props.informations)}}
          stick={false} />
      }
      {sms !== undefined && sms !== null && sms !== "" &&
        <IconTextBubble
          text={sms}
          bubble={{
            text: whiteLabel.textualContent.page.concierge.sidebarTextIcon,
            icon: {class: 'icon adn adn-message-processing', text: ''},
          }}
          type='sms'
          aTag={true}
          onClick={() => {analytics.sendSmsContactEvent(props.informations)}}
          stick={false} />
      }
      {chat !== undefined && chat !== null && chat !== "" &&
        <IconTextBubble
          text={chat}
          bubble={{
            text: whiteLabel.textualContent.page.concierge.sidebarChatIcon,
            icon: {class: 'icon adn adn-forum', text: ''},
          }}
          type='chat'
          aTag={false}
          onClick={() => {analytics.sendChatContactEvent(props.informations)}}
          stick={false} />
      }
      {messenger !== undefined && messenger !== null && messenger !== "" &&
        <IconTextBubble
          link={messenger}
          bubble={{
            text: whiteLabel.textualContent.page.concierge.sidebarChatIcon,
            icon: {class: 'icon adn adn-facebook-messenger', text: ''},
          }}
          type='messenger'
          aTag={true}
          onClick={() => {analytics.sendMessengerContactEvent(props.informations)}}
          stick={false} />
      }
      {mail !== undefined && mail !== null && mail !== "" &&
        <IconTextBubble
          text={mail}
          bubble={{
            text: whiteLabel.textualContent.page.concierge.sidebarEmailIcon,
            icon: {class: 'icon adn adn-email'},
          }}
          type='mail'
          aTag={true}
          onClick={() => {analytics.sendMailContactEvent(props.informations)}}
          stick={false} />
      }
      {(name || address1 || address2 || postalCode || city) && isMobile &&
        <IconTextBubble
          text={address}
          bubble={{
            text: whiteLabel.textualContent.page.concierge.sidebarAddressIcon,
            icon: {class: 'icon adn adn-home'},
          }}
          type='place'
          aTag={false}
          onClick={() => {analytics.sendAddressContactEvent(props.informations)}}
          stick={false} />
      }

    </ContactWrapper>
  );
}

Contact.propTypes = {
  informations: PropTypes.object.isRequired,
}

export default Contact;
