import styled from 'styled-components';

export const BubbleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${props => { return props.theme.color.primaryColor }};
  margin-right: ${props => { return props.stick ? '.2rem' : '1rem' }};
  color: white;
  padding-top: ${props => { return props.adn ? '0' : '10px' }};
  i {
    font-size: 2rem;
  }
  p {
    font-size: ${props => `${8*props.theme.font.primaryFont.scaling/100}px`};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    margin-bottom: 0;
  }
`;
