import React, { useContext } from 'react';
import Text from 'ToolboxComponents/commons/texts/text/text';
import {ThemeContext} from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import { ServiceWrapper } from './service-product-card.styles';

const ServiceSlide = props => {
  const theme = useContext(ThemeContext).state;
  return (
    <ServiceWrapper theme={theme}>
      <i className={props.icons[props.index]}></i>
      <h3><Text path={props.slide.title} /></h3>
      <Text path={props.slide.description} />
    </ServiceWrapper>
  );
};

ServiceSlide.propTypes = {
  slide: PropTypes.object,
};

export default ServiceSlide;
