import React, { useContext } from 'react';
import SliderServices from "ToolboxComponents/commons/sliders/slider-services/slider-services";
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import { TrustWrapper } from './trust-us.styles';

const Trust = props => {
  const theme = useContext(ThemeContext).state;
  const services = [
    {
      title: 'page.concierge.servicesTitle1',
      description: 'page.concierge.servicesDescription1',
    },
    {
      title: 'page.concierge.servicesTitle2',
      description: 'page.concierge.servicesDescription2',
    },
    {
      title: 'page.concierge.servicesTitle3',
      description: 'page.concierge.servicesDescription3',
    },
  ];

  return (
    <TrustWrapper theme={theme}>
      <h2 className="title"><Text path='page.concierge.servicesTitle' /></h2>
      <h2 className="title second-line"><Text path='page.concierge.servicesDescription' /></h2>
      <SliderServices
        services={services}
      />
    </TrustWrapper>
  );
}

export default Trust;
