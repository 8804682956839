import styled from 'styled-components';

export const ConciergeViewWrapper = styled.div`
  h1, h2 {
    font-size: 1.75rem;
  }
  h2.size2 {
    font-size: 1.5rem;
  }
  .mobile-header {
    display: ${props => (props.isMobile === true ? 'flex' : 'none')};
    align-items: center;
    justify-content: space-around;
    padding: .6rem 0;
    background: #383838;
    color: white;
    cursor: pointer;
    p {
      margin: 0;
    }
    i {
      font-size: 2rem;
    }
  }
  .img-header {
    height: ${props => (props.isMobile === true ? '250px' : '430px')};
    width: ${props =>
      props.isMobile === true ? '100%' : 'calc(100% - 300px)'};
    object-fit: cover;
  }
  .title {
    margin-top: 2rem;
    text-transform: uppercase;
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-align: center;
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
  }
  .first-title {
    position: ${props => (props.isMobile === true ? 'inherit' : 'sticky')};
    z-index: 2;
    background: white;
    top: ${props => (props.isMobile === true ? '' : props.firstTitleMarginTop)};
    margin-top: ${props =>
      props.isMobile === true ? '2rem' : props.firstTitleMarginTop};
    border-radius: ${props => (props.isMobile === '' ? '' : '15px 15px 0 0')};
    padding: ${props => (props.isMobile === '' ? '' : '10px 10px 0 10px')};
    margin-bottom: ${props => (props.isMobile === true ? '' : '2rem;')};
    width: ${props => (props.isMobile === true ? '' : 'calc(100% + 30px)')};
  }
  .bloc-text {
    text-align: ${props => (props.isMobile === true ? 'justify' : 'inherit')};
  }
  .colored {
    color: ${props => {
      return props.theme.color.primaryColor;
    }};
  }
  .last-title {
    line-height: 1.2 !important;
    font-size: 1.75rem;
    margin: 4rem auto;
  }
  .slider-experts {
    width: ${props =>
      props.isMobile === true ? '100%' : 'calc(100% - 300px)'};
    background: ${props => {
      return props.theme.color.primaryColor;
    }};
    color: white;
    padding: 1rem 0;
    text-align: center;
    border-radius: 10px;
    h5 {
      margin: 0;
    }
    .slider-experts-wrapper {
      padding: ${props => (props.isMobile === 'true' ? '0 1rem' : '0 .5rem')};
    }
  }
  .promotion {
    .suggestions {
      div {
        margin-bottom: 12px;
      }
      background: ${props => {
        return props.theme.color.primaryColor;
      }};
      color: white;
      font-family: ${props => props.theme.font.secondaryFont.name};
      text-transform: uppercase;
      padding: 1rem;
      padding-bottom: 0
      border-radius: 10px;
      margin: auto;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      max-width: 500px;
      span {
        font-size: ${props => `${20*props.theme.font.secondaryFont.scaling/100}px`};
      }
      .firstCatchPhrase, .lastCatchPhrase, .lastCatchPhrase3 {
        text-align: right;
      }
      .dots {
        margin-bottom: 0;
        text-align: right;
      }
    }
  }
  .contact {
    .bloc {
      display: block;
    }
  }

  .contact {
    padding: 1rem;
  }
  .container-title {
    padding-right: ${props => (props.isMobile === true ? '15px' : '4rem')};
    padding-left: ${props => (props.isMobile === true ? '15px' : '4rem')};
  }
  .container {
    width: ${props => props.isMobile === true ? '100%' : 'calc(100% - 300px)'};
    margin: 0;
  }
  .slider-experts-container {
    margin: ${props => (props.isMobile === true ? 'auto' : '0 4rem')};
    margin-bottom: 3rem;
  }
  @media (min-width: 1200px) {
    .container {
      max-width: none;
    }
    .container-title {
      padding-right: 9rem;
      padding-left: 9rem;
    }
    .slider-experts-container {
      margin: 0;
    }
  }
  @media (min-width: 850px) {
    .contact {
      position: sticky;
      top: ${props => props.contactTopPosition};
      margin-left: calc(100% - 300px);
      margin-top: -430px;
      height: 430px;
      width: 300px;
    }
    .promotion {
      .suggestions {
        span {
          font-size: 24px;
        }
      }
    }
  }
`;
