import React from 'react';
import Slider from "ToolboxComponents/commons/sliders/slider/slider/slider";
import ExpertSlide from 'ToolboxComponents/commons/cards/expert-product-card/expert-product-card';

const SliderExperts = props => {
  return (
    <Slider
      desktop={{
        dots: true,
        arrows: true
      }}
      mobile={{
        dots: true
      }}
      dotsColorActive="white"
    >
      {props.experts.map((expert, index) => (
        <ExpertSlide
          key={index}
          expert={expert}
        />
      ))}
    </Slider>
  );
};

export default SliderExperts;
