import React, { useState, useEffect, useContext} from 'react';
import { ThemeContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import Contact from "ToolboxComponents/webapp/blocks/contact/contact";
import Trust from "ToolboxComponents/webapp/blocks/trust-us/trust-us";
import SliderExperts from "ToolboxComponents/commons/sliders/slider-experts/slider-experts";
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import Text from 'ToolboxComponents/commons/texts/text/text';
import getText from "ToolboxUtils/web/get-text";


import { ConciergeViewWrapper } from './concierge-view.styles';

const ConciergeView = ({ concierge }) => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const theme = useContext(ThemeContext).state;
  const { experts } = concierge;
  const catchPhrases = [
    'page.concierge.catchPhrase1',
    'page.concierge.catchPhrase2',
    'page.concierge.catchPhrase3',
    'page.concierge.catchPhrase4'
  ];

  if (whiteLabel.textualContent.page.concierge.catchPhrase5) {
    catchPhrases.push('page.concierge.catchPhrase5')
  }

  if (whiteLabel.textualContent.page.concierge.catchPhrase6) {
    catchPhrases.push('page.concierge.catchPhrase6')
  }


  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [contactTopPosition, setContactTopPosition] = useState(null);
  useEffect(() => {
    setTimeout(function(){
      setContactTopPosition(`${document.getElementById('main-nav').offsetHeight}px`);
    }, 100);
  }, []);
  const [firstTitleMarginTop, setFirstTitleMarginTop] = useState(null);
  useEffect(() => {
    setTimeout(function(){
      setFirstTitleMarginTop(`-${document.getElementsByClassName('first-title')[0].offsetHeight}px`);
    }, 100);
  }, []);

  const goToContact = () => {
    const contact = document.getElementById('contact');
    window.scrollTo({
      top: contact.offsetTop,
      behavior: 'smooth',
    });
  }

  return (
    <ConciergeViewWrapper
      theme={theme}
      isMobile={isMobile}
      contactTopPosition={contactTopPosition}
      firstTitleMarginTop={firstTitleMarginTop}
    >
      <div className='mobile-header' onClick={() => goToContact()}>
        <i className='icon adn adn-phone' />
        <i className='icon adn adn-message-processing' />
        <Text path='page.concierge.goToContact' />
        <i className='icon adn adn-facebook-messenger' />
        <i className='icon adn adn-email' />
      </div>
      {isMobile ? whiteLabel.id === 8 ||  (whiteLabel.id === 10 || whiteLabel.id === 24)  ?
        <img src={whiteLabel.pictures.conciergePhoto} className="img-header ici1" alt='description' />:
        <img src={concierge.photo} className="img-header ici2" alt='description' />
        : <React.Fragment>
            {whiteLabel.id === 8 ||  (whiteLabel.id === 10 || whiteLabel.id === 24)  ?
              <img src={whiteLabel.pictures.conciergePhoto} className="img-header ici3" alt='description' />:
              <img src={concierge.photo} className="img-header ici4" alt='description' />
            }
            <Contact informations={concierge} />
          </React.Fragment>
      }
      <div className='container container-title'>
        <h1 className="title first-title"><Text path='page.concierge.pageTitle' /></h1>
      </div>
      <div className='container'>
        <div className='bloc-text'><Text path='page.concierge.pageDescription' /></div>
        <Trust />
      </div>
      {experts && experts.length > 0 &&
        <div className='slider-experts-container'>
          <div className='slider-experts'>
            <h5 className="title"><Text path='page.concierge.expertsTitle' /></h5>
            <Text path='page.concierge.expertsDescription' />
            <div className='slider-experts-wrapper'>
              <SliderExperts
                experts={experts}
              />
            </div>
          </div>
        </div>
      }
      <div className='container'>
        <div className='promotion'>
          <h2 className="title size2"><Text path='page.concierge.block4Title' /></h2>
          <div className='bloc-text'><Text path='page.concierge.block4Description' /></div>
          <div className='suggestions'>
            <div className='firstCatchPhrase'><Text path={catchPhrases[0]} /></div>
            <div className='middleCatchPhrase'><Text path={catchPhrases[1]} /></div>
            <div className='middleCatchPhrase'><Text path={catchPhrases[2]} /></div>
            <div className='lastCatchPhrase'><Text path={catchPhrases[3]} /></div>
            {catchPhrases.length > 4 && <div className='lastCatchPhrase2'><Text path={catchPhrases[4]} /></div>}
            {catchPhrases.length > 5 && <div className='lastCatchPhrase3'><Text path={catchPhrases[5]} /></div>}
            <p className='dots'>...</p>
          </div>
          <h2 className="title size2"><Text path='page.concierge.block6Title' /></h2>
          <div className='bloc-text'><Text path='page.concierge.block6Description' /></div>
        </div>
        <p className="title colored last-title"><Text path='page.concierge.lastTitle' /></p>
      </div>
      {isMobile &&
        <Contact informations={concierge} />
      }
    </ConciergeViewWrapper>
  );
}

ConciergeView.propTypes = {
  concierge: PropTypes.object.isRequired,
}

export default ConciergeView;
