import styled from 'styled-components';

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.color.tertiaryColor};
  margin-bottom: ${props => {
    return props.marginBottom;
  }};
  h2 {
    text-align: center;
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-transform: uppercase;
    color: ${props => {
      return props.theme.color.primaryColor;
    }};
  }
  .subtitle {
    text-align: center;
  }
`;
