import React, { useState, useEffect, useContext } from 'react';
import {ThemeContext, LanguageContext, WhiteLabelContext} from "ToolboxUtils/web/context/context";
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import PropTypes from 'prop-types';
import { ExpertWrapper } from './expert-product-card.styles';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";



const ExpertSlide = props => {
  const { expert } = props;
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  // TODO : remplacer par un texte personnalisable
  const bioText =  (whiteLabel.id === 10 || whiteLabel.id === 24)  ? {fr: 'Mon coup de coeur : ', en: 'My favorite: '} : {fr: 'La bio : ', en: 'Biography: '}; 

  return (
    <ExpertWrapper theme={theme} isMobile={isMobile}>
      <div className='first-block'>
        <ImageResize
          src={expert.photo}
        />
        {expert.accountSecretCount > 0 &&
          <div className='secrets-block'>
            <p className='secrets'>
              <span className='first-line'>
                <span className='secretsNumber'>{expert.accountSecretCount}</span>
                <span className='uppercase'>
                  {`secret${expert.accountSecretCount > 1 ? 's ' : ' '}`}
                  {`chuchoté${expert.accountSecretCount > 1 ? 's' : ''}`}
                </span>
              </span>
              <br />
              <span className='expert-link'>
                <Anchor code='public' pageParams={`/${expert.id}`}>{{fr: 'à découvrir ici', en: 'find out here'}[language]}</Anchor>
              </span>
            </p>
          </div>
        }
      </div>
      <div className='second-block'>
        <div className='expert-title'>
          <h4>{expert.firstName}</h4>
          <p>{expert.teaser}</p>
        </div>
        <div className='description'>
          <p><span className='text-type'>{bioText[language]}</span>{expert.biography}</p>
          {expert.anecdote && <p><span className='text-type'>{{fr: "L'anecdote : ", en: 'Anecdote: '}[language]}</span>{expert.anecdote}</p>}
          {expert.specialSign && <p><span className='text-type'>{{fr: 'Signe Particulier : ', en: 'Distinguishing feature: '}[language]}</span>{expert.specialSign}</p>}
          <div className='fade-out'>
            <div className='fade-out-text'></div>
          </div>
        </div>
      </div>
    </ExpertWrapper>
  );
};

ExpertSlide.propTypes = {
  expert: PropTypes.object,
};

export default ExpertSlide;
