import React, {useContext} from 'react';
import Slider from "ToolboxComponents/commons/sliders/slider/slider/slider";
import ServiceSlide from 'ToolboxComponents/commons/cards/service-product-card/service-product-card';
import { WhiteLabelContext } from 'ToolboxUtils/web/context/context';

const SliderServices = props => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  return (
    <Slider
      desktop={{
        slidesPerView: 3
      }}
      mobile={{
        dots: true
      }}
    >
      {props.services.map((service, index) => (
         (whiteLabel.id === 10 || whiteLabel.id === 24)  ?
            <ServiceSlide
                key={index}
                slide={service}
                index={index}
                icons={['icon adn adn-map-marker','icon adn adn-emoticon-outline','icon adn adn-gift']}
            />
            :
            <ServiceSlide
              key={index}
              slide={service}
              index={index}
              icons={['icon adn adn-map-marker','icon adn adn-gift','icon adn adn-lightbulb-on-outline']}
            />
      ))}
    </Slider>
  );
};

export default SliderServices;
