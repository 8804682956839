import React, { useContext } from 'react';
import {ThemeContext} from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import { BubbleWrapper } from './bubble.styles';

const Bubble = props => {
  const theme = useContext(ThemeContext).state;
  let adn = false;
  if (props.bubble.icon.class.includes('adn')) {
    adn = true;
  }
  return (
    <BubbleWrapper adn={adn} theme={theme}>
      <i className={props.bubble.icon.class}>{props.bubble.icon.text}</i>
      <p>{props.bubble.text}</p>
    </BubbleWrapper>
  )
}

Bubble.propTypes = {
  bubble: PropTypes.object.isRequired,
}

export default Bubble;
