import styled from 'styled-components';

export const TrustWrapper = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 3rem;
  .second-line {
    font-size: 1rem;
    margin-top: 0 !important;
    color: ${props => { return props.theme.color.primaryColor }};
  }
  .title {
    text-align: center;
  }
`;
