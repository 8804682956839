import styled from 'styled-components';

export const ExpertWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  background: white;
  border-radius: 20px;
  color: #212529;
  .first-block {
    display: flex;
    flex-direction: ${props => (props.isMobile ? 'row' : 'column')};
    justify-content: center;
    background: ${props =>
      props.isMobile ? 'inherit' : props.theme.color.tertiaryColor};
    border-radius: ${props => (props.isMobile ? '20px' : '20px 0 0 20px')};
    margin-top: ${props => (props.isMobile ? '0.5rem' : '')};
    img {
      z-index: 1;
      height: 200px;
      width: 200px;
      object-fit: cover;
      border-radius: ${props => (props.isMobile ? '100px' : '1rem')};
      border: 10px solid ${props => props.theme.color.tertiaryColor};
    }
    .secrets-block {
      display: flex;
      align-items: center;
      width: ${props => (props.isMobile ? '38%' : 'inherit')};
      .secrets {
        margin: 0;
        margin-left: ${props => (props.isMobile ? '-30px' : '1rem')};
        padding: ${props =>
          props.isMobile ? '10px 10px 10px 30px' : '10px 20px'};
        background: ${props => props.theme.color.tertiaryColor};
        color: ${props => props.theme.color.primaryColor}
        border-radius: ${props =>
          props.isMobile ? '0 20px 20px 0' : '0 0 0 20px'};
        .first-line {
          display: flex;
          margin-bottom: -30px;
          .secretsNumber {
            float: left;
            margin: -10px 0;
            font-family: ${props => props.theme.font.secondaryFont.name};
            font-weight: ${props =>
              parseInt(props.theme.font.secondaryFont.weight) + 300};
            font-size: ${props =>
              `${(48 * props.theme.font.secondaryFont.scaling) / 100}px`};
            padding-right: 5px;
          }
          .uppercase {
            text-align: left;
            font-family: ${props => props.theme.font.secondaryFont.name};
            text-transform: uppercase;
            font-weight: ${props =>
              parseInt(props.theme.font.secondaryFont.weight) + 300};
            color: #212529;
          }
        }
        .expert-link{
          a {
            display: inline-block;
            margin-left: -1rem;
          }
        }
      }
    }
  }
  .second-block {
    padding-top: 1rem;
  }
  .expert-title {
    text-align: center;
    h4 {
      font-family: ${props => props.theme.font.secondaryFont.name};
      text-transform: uppercase;
      font-weight: ${props =>
        parseInt(props.theme.font.secondaryFont.weight) + 300};
    }
    p {
      color: ${props => props.theme.color.primaryColor}
    }
  }
  .description {
    padding: 0 1rem;
    height: ${props => (props.isMobile ? '10rem' : '11rem')};
    overflow-y: auto;
    margin-bottom: 1rem;
    text-align: left;
    .text-type {
      color: ${props => props.theme.color.primaryColor}
    }
    .fade-out {
      width: ${props => (props.isMobile ? '100%' : '48%')};
      height: 40px;
      position: absolute;
      bottom: 15px;
      right: 5px;
      .fade-out-text {
        background: linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1));
        height: 100%;
        width: 100%;
      }
    }
  }
`;
