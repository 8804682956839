import styled from 'styled-components';

export const IconTextBubbleWrapper = styled.div`
  color: #2D2D2D;
  margin-bottom: ${props => { return props.noSpaceBottom ? '0' : '1rem' }};
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .highlighted {
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  }
  a {
    color: #2D2D2D !important;
  }
  a:hover {
    text-decoration: none;
  }
  .text {
    color: ${props => { return props.textColor ? props.textColor : 'inherit' }};
  }
  .description {
    margin: 0;
  }
`;
