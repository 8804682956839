import styled from 'styled-components';

export const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  i {
    color: ${props => props.theme.color.primaryColor};
    font-size: 7em;
    text-align: center;
  }
  h3 {
    font-size: 1.5rem;
    text-align: center;
    color: ${props => props.theme.color.primaryColor};
  }
`;
